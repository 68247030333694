@use "../../style/abstracts/variables/breakpoints";
@use "../../style/abstracts/variables/colors";

.footer {
  background-color: #eee;

  & .contentSection {
    max-width: 1200px;
    padding: 16px;
    margin: auto;
    flex: 1;

    & .map {
      margin: 0;

      & img {
        width: 100%;
        height: auto;
      }
    }

    & .grid {
      @media (min-width: breakpoints.$screen-md) {
        display: flex;
      }

      & .textSection {
        @media (min-width: breakpoints.$screen-md) {
          flex: 1;
        }
      }

      & .mapSection {
        @media (min-width: breakpoints.$screen-md) {
          flex: 2;
        }
      }
    }
  }

  & .addressContent {
    & ul {
      list-style: none;
      padding: 0;

      & li {
        margin: 6px 0;
        a {
          color: colors.$default-text-color;
        }
      }
    }
  }
}
