* {
  -webkit-box-sizing: border-box; // Safari <= 5
  -moz-box-sizing: border-box; // Firefox <= 19
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: none;
}

button,
input[type="button"],
input[type="submit"] {
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
