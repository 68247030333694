@use "../../../style/abstracts/mixins/box-shadow";
@use "../../../style/abstracts/mixins/transition";
@use "../../../style/abstracts/variables/breakpoints";
@use "../../../style/abstracts/variables/colors";

.hidden {
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
}

.searchFieldContainer {
  @include transition.transition(
    max-width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin-left 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
  );

  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  width: 100%;
  max-width: 150px;
  margin-left: 14px;

  @media (min-width: 350px) {
    max-width: 170px;
    margin-left: 4px;
    &:focus-within {
      max-width: 200px;
    }
  }
  @media (min-width: breakpoints.$screen-sm) {
    max-width: 220px;
    margin-left: 81px;
    &:focus-within {
      max-width: 250px;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }

  & svg {
    position: relative;
    left: 16px;
    margin-right: -16px;
  }

  & input.searchField {
    border-color: transparent;
    box-shadow: none;
    padding: 10.75px 8px 10.75px 40px;
    background: none;
    font-size: 16px;
    color: #fff;
    position: relative;
    z-index: 2;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.5);
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.5);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.resultsListContainer {
  display: none;
  position: absolute;
  top: 52px;
  left: 0;
  width: 100%;
  padding: 0 16px;
  @media (min-width: breakpoints.$screen-sm) {
    top: 56px;
  }

  &.active {
    display: block;
  }

  & .resultsList {
    @include box-shadow.box-shadow(
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12)
    );
    color: colors.$default-text-color;
    background-color: #fff;
    padding: 8px 0;
    border-radius: 4px;
    margin: auto;
    max-height: 180px;
    overflow-y: auto;
    @media (min-width: breakpoints.$screen-xs) {
      max-height: 220px;
    }
    @media (min-width: breakpoints.$screen-sm) {
      max-width: 600px;
      max-height: 450px;
      min-width: 150px;
    }

    & .resultsListItem {
      color: colors.$default-text-color;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      text-decoration: none;

      &:focus,
      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.38);
      }

      > svg {
        margin-right: 3px;
      }

      & picture {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        overflow: hidden;
      }

      & .resultsListItemText {
        flex: 1;
        padding: 0 8px;
      }

      & .resultsListItemTypeLabel {
        width: 105px;
        text-align: right;
        font-size: 0.75em;
        font-weight: bold;

        > span {
          background-color: #eee;
          border-radius: 4px;
          padding: 6px;
          color: #fff;

          & svg {
            margin-right: 2px;
          }
        }

        &.release {
          > span {
            background-color: colors.$blue-color;
          }
        }

        &.post {
          > span {
            background-color: colors.$primary-color;
          }
        }

        &.video {
          > span {
            background-color: colors.$yellow-color;
          }
        }

        &.product {
          > span {
            background-color: colors.$indigo-color;
          }
        }

        &.instruments {
          > span {
            background-color: colors.$orange-color;
          }
        }

        &.effects {
          > span {
            background-color: colors.$purple-color;
          }
        }

        &.amplifiers {
          > span {
            background-color: colors.$green-color;
          }
        }
      }
    }
  }
}
