@use "style/abstracts/mixins/calc";
@use "style/abstracts/variables/breakpoints";

.container {
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  @include calc.calc('height', '100% - 56px');
  @media (min-width: breakpoints.$screen-sm) {
    @include calc.calc('height', '100% - 64px');
  }
  & a:focus {
    text-decoration: underline;
  }
}
